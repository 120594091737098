<template>
    <div>
        <b-card bg-variant="Success" class="text-center" v-if="submitted">
          <b-button
          variant="primary"
          :to="{ name: 'crm-leads-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Leads
          </b-button>
        </b-card>

        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'crm-utenti-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista Utenti
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                    <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Dettagli</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row>
                      <b-col md="4" lg="6">
                        <b-form-group label="Nome *" label-for="name">
                            <validation-provider
                            #default="{ errors }"
                            name="Nome"
                            rules="required"
                            >
                            <b-form-input
                                v-model="campiform.agent.name"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="6">
                        <b-form-group label="Cognome *" label-for="Surname">
                            <validation-provider
                            #default="{ errors }"
                            name="Cognome"
                            rules="required"
                            >
                            <b-form-input
                                v-model="campiform.agent.surname"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="4" lg="6">
                        <b-form-group label="Email *" label-for="email">
                            <validation-provider
                            #default="{ errors }"
                            name="Email dell'utente"
                            rules="required|email"
                            >
                            <b-form-input
                                v-model="campiform.agent.email"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Cellulare" label-for="Cellulare">
                            <b-form-input
                                v-model="campiform.agent.mobile_phone"
                                placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Data di nascita" label-for="Data di nascita">
                            <b-form-input
                              v-model="campiform.agent.birthday"
                              type="text"
                              placeholder="YYYY-MM-DD"
                              autocomplete="off"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="12">
                        <b-form-group label="Indirizzo" label-for="address">
                            <b-form-input
                                v-model="campiform.agent.address"
                                placeholder=""
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="6">
                        <b-form-group label="Città *" label-for="city">
                          <validation-provider
                          #default="{ errors }"
                          name="Città"
                          rules="required|min:2"
                          >
                            <b-form-input
                                v-model="campiform.agent.city"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Provincia *" label-for="province_id">
                          <validation-provider
                          #default="{ errors }"
                          name="Provincia"
                          rules="required|min:2"
                          >
                            <vue-autosuggest
                              ref="autocomplete"
                              v-model="valoreDefaultNazione"
                              :suggestions="filteredOptions"
                              :limit="8"
                              :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'cerca provincia', }"
                              :render-suggestion="renderSuggestion"
                              :get-suggestion-value="getSuggestionValue"
                              @input="onInputChange"
                              @selected="selectHandler"
                            >
                              <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                              </template>
                            </vue-autosuggest>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="3">
                        <b-form-group label="Cap" label-for="zip">
                          <validation-provider
                          #default="{ errors }"
                          name="Cap"
                          rules="digits:5"
                          >
                          <b-form-input
                              v-model="campiform.agent.zip"
                              :state="errors.length > 0 ? false:null"
                              placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="4">
                        <b-form-group label="Sesso *" label-for="sesso">
                          <validation-provider
                          #default="{ errors }"
                          name="Sesso"
                          rules="required"
                          >
                            <b-form-select
                                v-model="campiform.agent.id_gender"
                                :options="optionsSesso"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="4">
                        <b-form-group label="Lingua madre *" label-for="lingua_madre">
                          <validation-provider
                          #default="{ errors }"
                          name="Lingua madre"
                          rules="required"
                          >
                            <b-form-select
                                v-model="campiform.agent.id_native_lang"
                                :options="optionsLang"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4" lg="4">
                        <b-form-group
                          label="Titolo di studio"
                          label-for="titolo-di-studio"
                        >
                          <multiselect
                            v-model="campiform.agent.degree"
                            :options="optionsTitoloDiStudio"
                            :custom-label="multiSelect_act_Lingua" 
                            placeholder="seleziona o ricerca"
                            :multiple="false"
                            :close-on-select="true" 
                            :clear-on-select="false"
                            :preserve-search="true"
                            :max-height="250"
                            group-values="items"
                            group-label="groupname" 
                            :group-select="false" 
                            label="Titolo di studio" 
                            track-by="text"
                          >
                            <span slot="noResult">Nessun risultato</span>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="4">
                        <b-form-group label="Provenienza" label-for="provenienza">
                            <b-form-select
                                v-model="campiform.agent.id_provenance"
                                :options="optionsProvenienza"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="4">
                        <b-form-group label="Allineamento" label-for="allineamento">
                            <b-form-select
                                v-model="campiform.agent.id_align"
                                :options="optionsAllineamento"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" lg="4">
                        <b-form-group label="CV 1 *" label-for="cv">
                          <validation-provider
                          #default="{ errors }"
                          name="CV"
                          rules="required"
                          >
                            <b-form-file
                                v-model="cv_1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    </b-row>

                  </b-card>
                </b-tab>

              
                <b-tab>
                  <template #title>
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Skills</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row>
                      <b-col cols="12" lg="6">
                        <b-form-group
                          label="Profilo"
                          label-for="v-adse-profilo"
                        >
                          <multiselect
                            v-model="campiform.agent_profiles"
                            :options="optionsProfilo"
                            :custom-label="multiSelect_act_Lingua" 
                            placeholder="seleziona o ricerca"
                            :multiple="true"
                            :close-on-select="false" 
                            :clear-on-select="false"
                            :preserve-search="true"
                            :show-labels="false"
                            :max-height="250"
                            :max="5"
                            group-values="items"
                            group-label="groupname" 
                            :group-select="true" 
                            label="profilo" 
                            track-by="text" 
                          >
                            <template #optiongroup="slotProps">
                              <div>{{ slotProps.option.label }}</div>
                            </template>
                            <span slot="noResult">Nessun risultato</span>
                            <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                          label="Regioni"
                          label-for="v-adse-regioni"
                        >
                          <multiselect
                            v-model="campiform.agent_regions"
                            :options="optionsRegioni"
                            :custom-label="multiSelect_act_Lingua" 
                            placeholder="seleziona o ricerca"
                            :multiple="true"
                            :close-on-select="false" 
                            :clear-on-select="false"
                            :preserve-search="true"
                            :show-labels="false"
                            :max-height="250"
                            :max="5"
                            label="regioni" 
                            track-by="text" 
                          >
                            <span slot="noResult">Nessun risultato</span>
                            <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                          label="Settore"
                          label-for="v-adse-settore"
                        >
                          <multiselect
                            v-model="campiform.agent_sectors"
                            :options="optionsSettori"
                            :custom-label="multiSelect_act_Lingua" 
                            placeholder="seleziona o ricerca"
                            :multiple="true"
                            :close-on-select="false" 
                            :clear-on-select="false"
                            :preserve-search="true"
                            :show-labels="false"
                            :max-height="250"
                            :max="5"
                            group-values="items"
                            group-label="groupname" 
                            :group-select="true" 
                            label="settore" 
                            track-by="text" 
                          >
                            <template #optiongroup="slotProps">
                              <div>{{ slotProps.option.label }}</div>
                            </template>
                            <span slot="noResult">Nessun risultato</span>
                            <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                          label="Altre lingue"
                          label-for="v-adse-altre-lingue"
                        >
                          <multiselect
                            v-model="campiform.agent_langs"
                            :options="optionsLangSecondary"
                            :custom-label="multiSelect_act_Lingua" 
                            placeholder="seleziona o ricerca"
                            :multiple="true"
                            :close-on-select="false" 
                            :clear-on-select="false"
                            :preserve-search="true"
                            :show-labels="false"
                            :max-height="250"
                            :max="7" 
                            label="settore" 
                            track-by="text" 
                          >
                            <template #optiongroup="slotProps">
                              <div>{{ slotProps.option.label }}</div>
                            </template>
                            <span slot="noResult">Nessun risultato</span>
                            <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col md="3" lg="2">
                        <b-form-group label="Enasarco" label-for="enasarco">
                          <b-form-checkbox
                            checked="false"
                            name="check-button"
                            switch
                            id="v-adse-enasarco"
                            v-model="campiform.agent.enasarco"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="3" lg="2">
                        <b-form-group label="Partita Iva" label-for="piva">
                          <b-form-checkbox
                            checked="false"
                            name="check-button"
                            switch
                            inline
                            id="v-adse-vat"
                            v-model="campiform.agent.vat"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6" lg="8">&nbsp;</b-col>
                      <b-col md="12" lg="6">
                        <b-form-group label="Skills in sintesi" label-for="skills">
                          <b-form-textarea 
                            id="textarea-skills_summary"
                            rows="3"
                            v-model="campiform.agent.skills_summary"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="6">
                        <b-form-group label="Note" label-for="note">
                          <b-form-textarea 
                            id="textarea-note"
                            rows="3"
                            v-model="campiform.agent.note"
                          />
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-card>
                </b-tab>

              </b-tabs>

              <b-row>
                <b-col md="12" class="text-center">
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="action_press"
                    @click.prevent="validationForm()"
                  >
                    <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                    <div v-else>Salva</div> 
                  </b-button>
                </b-col>
              </b-row>
              
            </b-form>
          </validation-observer>
        </div>
          
    </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BFormCheckbox, BFormTextarea, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BFormDatepicker, BFormFile } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, digits, alphaDash, length, min
} from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import Multiselect from 'vue-multiselect' 

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BSpinner, 
    BCardText, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput, 
    BFormCheckbox, 
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BFormDatepicker,
    BFormFile,

    VueAutosuggest, 
    Multiselect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,     
  },
  data() {
    return {
      valoreDefaultNazione: "",
      campiform: {
        agent: { 
          name: '',
          surname: '',
          email: '',
          mobile_phone: '',
          birthday: '',
          address: '',
          zip: '',
          city: '',
          id_province: '',
          id_gender: '',
          degree: {
            value: '',
          },
          id_provenance: '',
          id_align: '',
          id_native_lang: '',
          vat: '',
          enasarco: '',
          skills_summary: '',
          note: '',
        },
        agent_langs: [ ],
        agent_profiles: [ ],
        agent_sectors: [ ],
        agent_regions: [ ],
      },
      cv_1: [],
      required,
      email,
      alphaDash, 
      digits,
      length,
      min,
      
      optionsLang: [ ],
      optionsLangSecondary: [ ],
      optionsAllineamento: [ ],
      optionsProfilo: [ ], 
      optionsProvenienza: [ ], 
      optionsSesso: [ ], 
      optionsSettori: [ ],
      optionsAziende: [ ], 
      optionsTitoloDiStudio: [ ],
      optionsRegioni: [ ], 

      Caricato: false,
      errore_caricamento: false,
      submitted: false,
      action_press: false,  

      filteredOptions: [],
      limit: 10,
    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const langPromise = this.$http.get('api/fs/lang/list')
    const AllinemanetoPromise = this.$http.get('api/crm/agents/select/allineamento')
    const ProfiloPromise = this.$http.get('api/crm/agents/select/profilo')
    const provenienzaPromise = this.$http.get('api/crm/agents/select/provenienza')
    const SessoPromise = this.$http.get('api/fs/gender/list')
    const SettoriPromise = this.$http.get('api/crm/agents/select/settore')
    const ProvincePromise = this.$http.get('api/fs/province/list')
    const TitoloDistudioPromise = this.$http.get('api/crm/agents/select/titolostudio')
    const RegioniPromise = this.$http.get('api/fs/regions/list')

    Promise.all([langPromise, AllinemanetoPromise,ProfiloPromise,provenienzaPromise,
    SessoPromise,SettoriPromise,ProvincePromise,TitoloDistudioPromise,RegioniPromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista Lingue
        this.optionsLang = response[0].data.reply.data
        this.optionsLangSecondary = response[0].data.reply.data

        if(response[1].data.statusCode===200){
          //caricamento lista Allinemaneto
          this.optionsAllineamento = response[1].data.reply

          if(response[2].data.statusCode===200){
            //caricamento lista Profilo
            this.optionsProfilo = response[2].data.reply

            if(response[3].data.statusCode===200){
              //caricamento lista Provenienze
              this.optionsProvenienza = response[3].data.reply

              if(response[4].data.statusCode===200){
                //caricamento lista Sesso
                this.optionsSesso = response[4].data.reply

                if(response[5].data.statusCode===200){
                  //caricamento lista Settori
                  this.optionsSettori = response[5].data.reply

                  if(response[6].data.statusCode===200){
                  //caricamento lista province
                    this.optionsProvince = response[6].data.reply

                    if(response[7].data.statusCode===200){
                    //caricamento lista titoli di studio
                      this.optionsTitoloDiStudio = response[7].data.reply

                      if(response[8].data.statusCode===200){
                      //caricamento lista regioni
                        this.optionsRegioni = response[8].data.reply

                        this.Caricato = true;
                        this.errore_caricamento = false;

                      } else {
                        this.Caricato = false;
                        this.errore_caricamento = true;
                      }

                    } else {
                      this.Caricato = false;
                      this.errore_caricamento = true;
                    }

                  } else {
                    this.Caricato = false;
                    this.errore_caricamento = true;
                  }

                } else {
                  this.Caricato = false;
                  this.errore_caricamento = true;
                }

              } else {
                this.Caricato = false;
                this.errore_caricamento = true;
              }

            } else {
              this.Caricato = false;
              this.errore_caricamento = true;
            }

          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }      
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })
  },
  methods: {
    onInputChange(testo_ricerca) {
      if (testo_ricerca === '' || testo_ricerca === undefined) {
        return
      }

      //filtra array nazioni
      const filteredData = this.optionsProvince.filter(item => item.text.toLowerCase().indexOf(testo_ricerca.toLowerCase()) == 0).slice(0, this.limit)
      
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    renderSuggestion(suggestion) {
      //per gestire le voci restituite nella finestra auto-suggest
      return suggestion.item.text;
    },
    getSuggestionValue(suggestion) {
      //per gestire il testo che verra visualizzato dopo la selezione dela voce dalla finestra dell'auto-suggest
      return suggestion.item.text;
    },
    selectHandler(text){
      if (text === '' || text === undefined) {
        //associao id_province a "nessuna"
        this.campiform.agent.id_province = 111
      } else {
        //associo id_province al parametro da passare all'api
        this.campiform.agent.id_province = text.item.value
      }
    },
    multiSelect_act_Lingua ({ value, text }) {
      return `${text}`
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //this.action_press = true

          //console.log(this.campiform)

          let formData = new FormData();

          //accodo FILE all'invio dati manualmente
          formData.append('file_cv_1', this.cv_1);
          formData.append('campiform', JSON.stringify(this.campiform));
          //formData.append('campiform', this.campiform);

          console.log("spedizione in partenza ...")
          console.log(formData)

          axios.post('api/crm/agents/savenew', formData, {
            header:{
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => { 
            //console.log("risposta server ------- sperimo bene ...")
            //console.log(response.data)

            if(response.data.statusCode===200){
                //risposta positiva
                if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                this.$router.replace('/crm/agenti')
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                })
                
              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.message,
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })

                this.action_press = false
              }
                
            } else {
              //risposta negativa (errore sul server)
              this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')

              this.action_press = false
            }

          })

        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-multiselect.min.scss';
</style>
